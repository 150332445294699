// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import backdropReducer from './slices/backdrop';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import workplaceReducer from './slices/workplace';
import campaignDetailReducer from './slices/campaignDetail';
import generatorReducer from './slices/generator';
import savedResultReducer from './slices/savedResult';
import profileReducer from './slices/profile';
import campaignReducer from './slices/campaign';
import packageReducer from './slices/package';
import creativeEditReducer from './slices/creativeEdit';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    backdrop: backdropReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    workplace: workplaceReducer,
    campaignDetail: campaignDetailReducer,
    generator: generatorReducer,
    savedResult: savedResultReducer,
    profile: profileReducer,
    campaign: campaignReducer,
    package: packageReducer,
    creativeEdit: creativeEditReducer
});

export default reducer;
