import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import CreativeEditLayout from 'layout/CreativeEditLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const CreativeEditPage = Loadable(lazy(() => import('views/creative-edit-page')));

// ==============================|| MAIN ROUTING ||============================== //

const CreativeEditRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <CreativeEditLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/creative-edit/:id',
            element: <CreativeEditPage />
        }
    ]
};

export default CreativeEditRoutes;
