import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import NewCampaignLayout from 'layout/NewCampaignLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const NewCampaignPage = Loadable(lazy(() => import('views/archive/new-campaign-page')));

// ==============================|| MAIN ROUTING ||============================== //

const NewCampaignRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <NewCampaignLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/new-campaign/old',
            element: <NewCampaignPage />
        }
    ]
};

export default NewCampaignRoutes;
