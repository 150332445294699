import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Person } from '@mui/icons-material';

import LogoSection from '../LogoSection';
import PageTitleSection from './PageTitleSection';
import useAuth from 'hooks/useAuth';

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    marginRight: '16px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }} onClick={handleClick}>
                    <Avatar sx={{ background: '#DDDDDD' }}>
                        <Person sx={{ color: '#000000' }} />
                    </Avatar>
                    <>
                        {anchorEl === null && <KeyboardArrowDown />}
                        {anchorEl !== null && <KeyboardArrowUp />}
                    </>
                </Box>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleLogout();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        </>
    );
};

export default Header;
