import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import NewCampaignRoutes from './NewCampaignRoutes';
import GeneratorRoutes from './GeneratorRoutes';
import PackageRoutes from './PackageRoutes';
import CreativeEditRoutes from './CreativeEditRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([LoginRoutes, MainRoutes, NewCampaignRoutes, GeneratorRoutes, PackageRoutes, CreativeEditRoutes]);
}
