import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import PackageLayout from 'layout/PackageLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const PackagePage = Loadable(lazy(() => import('views/package-page')));

// ==============================|| MAIN ROUTING ||============================== //

const PackageRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <PackageLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/package/:campaignId/:packageId',
            element: <PackagePage />
        }
    ]
};

export default PackageRoutes;
