import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Menu, MenuItem, OutlinedInput } from '@mui/material';
import { ArrowBackIosNew, KeyboardArrowDown, KeyboardArrowUp, Person } from '@mui/icons-material';
import { useDispatch, useSelector } from 'store';
import { updateTempPackgeName } from 'store/slices/package';

import LogoSection from '../LogoSection';
import useAuth from 'hooks/useAuth';

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logout } = useAuth();
    const { campaignId, packageId } = useParams();
    const { submitLock, packageInfo } = useSelector((state) => state.package);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [packageName, setPackname] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (packageId === 'new') {
            setPackname('New Package');
        }
    }, []);

    useEffect(() => {
        if (packageInfo && packageInfo?.id === packageId) {
            setPackname(packageInfo.name);
            dispatch(updateTempPackgeName(packageInfo.name));
        }
    }, [packageInfo]);

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    marginRight: '16px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <ArrowBackIosNew
                    sx={{
                        padding: '8px',
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        color: '#616161',
                        cursor: 'pointer',
                        '&:hover': {
                            background: '#DDDDDD'
                        }
                    }}
                    onClick={() => {
                        navigate(`/campaign/${campaignId}`);
                    }}
                />
                <OutlinedInput
                    id="package-name"
                    name="package-name"
                    value={packageName}
                    onChange={(e) => {
                        setPackname(e.target.value);
                        dispatch(updateTempPackgeName(e.target.value));
                    }}
                    inputProps={{
                        style: {
                            fontSize: '1.5rem',
                            padding: '0.5rem 1rem',
                            background: '#FFFFFF'
                        }
                    }}
                    sx={{
                        '&.MuiOutlinedInput-root': {
                            fieldset: {
                                borderColor: 'transparent'
                            },
                            textarea: {
                                bgcolor: '#FFFFFF'
                            },
                            '&:hover fieldset': {
                                borderColor: '#7A2897'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#7A2897'
                            }
                        }
                    }}
                />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Button
                        variant="contained"
                        form="package-form"
                        type="submit"
                        size="large"
                        id="package-submit"
                        sx={{
                            backgroundColor: '#7A2897',
                            color: '#FFFFFF',
                            fontWeight: 700,
                            marginRight: '1rem',
                            '&:hover': {
                                backgroundColor: '#7A2897',
                                color: '#FFFFFF'
                            }
                        }}
                        disabled={submitLock}
                    >
                        Save
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }} onClick={handleClick}>
                    <Avatar sx={{ background: '#DDDDDD' }}>
                        <Person sx={{ color: '#000000' }} />
                    </Avatar>
                    <>
                        {anchorEl === null && <KeyboardArrowDown />}
                        {anchorEl !== null && <KeyboardArrowUp />}
                    </>
                </Box>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleLogout();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        </>
    );
};

export default Header;
