import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    campaignInfo: null,
    creativeList: null,
    textList: null,
    packageList: null,
    textEditing: null
};

// ==============================|| SLICE - CAMPAIGN ||============================== //

const slice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCampaignInfo(state, action) {
            state.campaignInfo = action.payload;
        },
        setCreativeList(state, action) {
            state.creativeList = action.payload;
        },
        setTextList(state, action) {
            state.textList = action.payload;
        },
        setTextEditing(state, action) {
            state.textEditing = action.payload;
        },
        setPackageList(state, action) {
            state.packageList = action.payload;
        },
        reset(state) {
            state.error = null;
            state.campaignInfo = null;
            state.creativeList = null;
            state.textList = null;
            state.packageList = null;
            state.textEditing = null;
        }
    }
});

export default slice.reducer;

export function getCampaignDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCampaignInfo(response.data.campaign));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getCreativeList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCreativeList(response.data.creativeList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function generateMoreCreative(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/generate-more', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function duplicateCreative(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/duplicate', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateCreative(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getTextList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setTextList(response.data.promotionTextList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function generateMoreText(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/generate-more', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setTextList(response.data.promotionTextList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function duplicateText(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/duplicate', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log(response);
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateText(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log(response);
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getPackageList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/package/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPackageList(response.data.packageList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updatePackage(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/package/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateTextEditing(data) {
    return () => {
        dispatch(slice.actions.setTextEditing(data));
    };
}

export function clearStore(data, callback) {
    return () => {
        dispatch(slice.actions.reset());
        if (callback) {
            callback();
        }
    };
}
