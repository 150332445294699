import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    creativeInfo: null,
    creativeTemplateList: [],
    assetList: null
};

// ==============================|| SLICE - CREATIVE EDIT ||============================== //

const slice = createSlice({
    name: 'creativeEdit',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCreativeInfo(state, action) {
            state.creativeInfo = action.payload;
        },
        setCreativeTemplateList(state, action) {
            state.creativeTemplateList = action.payload;
        },
        setAssetList(state, action) {
            state.assetList = action.payload;
        }
    }
});

export default slice.reducer;

export function getCreativeInfo(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/creative/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCreativeInfo(response.data.creative));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function assetUpload(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/file/upload', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function combineImage(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/combine-image', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateCreative(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log(response);
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getAssetList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/asset/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setAssetList(response.data.assetList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getTemplate(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/properties/template', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCreativeTemplateList(response.data.creativeTemplateList));
            if (callback) {
                callback(response.data.creativeTemplateList);
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
