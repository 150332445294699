import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    getCampaign: false,
    getPackage: false,
    tempPackageName: null,
    submitLock: true,
    campaignInfo: null,
    creativeList: null,
    textList: null,
    packageInfo: null
};

// ==============================|| SLICE - PACKAGE ||============================== //

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        reset(state) {
            state.error = null;
            state.getCampaign = false;
            state.getPackage = false;
            state.tempPackageName = null;
            state.submitLock = true;
            state.campaignInfo = null;
            state.creativeList = null;
            state.textList = null;
            state.packageInfo = null;
        },
        setTempPackageName(state, action) {
            state.tempPackageName = action.payload;
        },
        setSubmitLock(state, action) {
            state.submitLock = action.payload;
        },
        setCampaignInfo(state, action) {
            state.getCampaign = true;
            state.campaignInfo = action.payload;
        },
        setCreativeList(state, action) {
            state.creativeList = action.payload;
        },
        setTextList(state, action) {
            state.textList = action.payload;
        },
        setPackageInfo(state, action) {
            state.getPackage = true;
            state.packageInfo = action.payload;
        }
    }
});

export default slice.reducer;

export function clearStore(data, callback) {
    return () => {
        dispatch(slice.actions.reset());
        if (callback) {
            callback();
        }
    };
}

export function updateTempPackgeName(data) {
    return () => dispatch(slice.actions.setTempPackageName(data));
}

export function updateSubmitLock(data) {
    return () => dispatch(slice.actions.setSubmitLock(data));
}

export function getCampaignDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCampaignInfo(response.data.campaign));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getCreativeList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCreativeList(response.data.creativeList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getTextList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setTextList(response.data.promotionTextList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getPackageDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/package/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPackageInfo(response.data.pkg));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function createPackage(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/package/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPackageInfo(response.data.pkg));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updatePackage(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/package/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPackageInfo(response.data.pkg));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
