// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconLayoutBoard, IconAdjustmentsHorizontal } from '@tabler/icons';

// constant
const icons = {
    IconLayoutBoard,
    IconAdjustmentsHorizontal
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const aiTools = {
    id: 'ai-tool',
    title: null,
    type: 'group',
    children: [
        {
            id: 'campaign',
            title: <FormattedMessage id="campaign" />,
            type: 'item',
            url: '/workplace',
            icon: icons.IconLayoutBoard,
            breadcrumbs: false
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'item',
            url: '/setting',
            icon: icons.IconAdjustmentsHorizontal,
            breadcrumbs: false
        }
    ]
};

export default aiTools;
