import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    brandLogoUrl: null,
    brand: null,
    website: null,
    userProfilePictureUrl: null,
    userFullName: null,
    userEmail: null
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setInfo(state, action) {
            state.brandLogoUrl = action.payload.brand.logoPath;
            state.brand = action.payload.brand.name;
            state.website = action.payload.brand.website;
            state.userProfilePictureUrl = action.payload.profilePic;
            state.userFullName = action.payload.name;
            state.userEmail = action.payload.email;
        }
    }
});

export default slice.reducer;

export function getProfileDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/profile/detail', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setInfo(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateProfile(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/profile/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setInfo(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function changePassword(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/profile/change-password', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
