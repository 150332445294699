import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    personaKeyword: null,
    goalList: null,
    campaign: null
};

// ==============================|| SLICE - CAMPAIGN DETAIL ||============================== //

const slice = createSlice({
    name: 'campaignDetail',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setFormProperty(state, action) {
            state.personaKeyword = action.payload.personaKeywords;
            state.goalList = action.payload.campaignGoalList;
        },
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        reset(state) {
            state.error = null;
            state.personaKeyword = null;
            state.goalList = null;
            state.campaign = null;
        }
    }
});

export default slice.reducer;

export function clearStore(data, callback) {
    return () => {
        dispatch(slice.actions.reset());
        if (callback) {
            callback();
        }
    };
}

export function getCampaignFormProperty(data, callback) {
    return async () => {
        try {
            const response = await axios.post(
                '/api/properties/list',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            );
            dispatch(slice.actions.setFormProperty(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getCampaignDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCampaign(response.data.campaign));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function createCampaign(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCampaign(response.data.campaign));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function regenerateCampaign(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/regenerate', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCampaign(response.data.campaign));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
